
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', { 'className': 'product' }, _createElement('div', {
                    'className': 'product-item',
                    'data-product-id': this.id
                }, _createElement('div', { 'className': 'card ajax-loaded' }, _createElement('div', { 'className': 'card-product' }, _createElement('div', { 'className': 'card-product__wrapper' }, _createElement('div', { 'className': 'card__badge badge-right halo-productBadges halo-productBadges--right' }, this.on_sale ? _createElement('span', {
                    'className': 'badge sale-badge',
                    'aria-hidden': 'true',
                    'key': '295'
                }, 'Sale') : null), _createElement('a', {
                    'className': 'card-media card-media--portrait media--hover-effect media--loading-effect',
                    'href': this.url,
                    'style': { paddingBottom: '90%' }
                }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'title': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'loading': 'lazy',
                    'className': 'motion-reduce lazyloaded'
                }), this.image2 ? _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image2)),
                    'alt': this.removeHTML(this.title),
                    'title': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'loading': 'lazy',
                    'className': 'motion-reduce lazyloaded',
                    'key': '778'
                }) : null), _createElement('div', { 'className': 'card-product__group group-left' }, _createElement('div', { 'className': 'card-product__group-item card-wishlist' }, _createElement('a', {
                    'className': 'wishlist-icon',
                    'href': '#',
                    'data-wishlist': true,
                    'data-wishlist-handle': this.handle,
                    'data-product-id': this.id
                }, _createElement('span', { 'className': 'visually-hidden' }, 'Add to wishlist'), _createElement('span', { 'className': 'text' }, ' Add to wishlist '), _createElement('svg', {
                    'viewBox': '0 0 512 512',
                    'className': 'icon icon-wishlist'
                }, _createElement('g', {}, _createElement('g', {}, _createElement('path', { 'd': 'M474.644,74.27C449.391,45.616,414.358,29.836,376,29.836c-53.948,0-88.103,32.22-107.255,59.25\n      c-4.969,7.014-9.196,14.047-12.745,20.665c-3.549-6.618-7.775-13.651-12.745-20.665c-19.152-27.03-53.307-59.25-107.255-59.25\n      c-38.358,0-73.391,15.781-98.645,44.435C13.267,101.605,0,138.213,0,177.351c0,42.603,16.633,82.228,52.345,124.7\n      c31.917,37.96,77.834,77.088,131.005,122.397c19.813,16.884,40.302,34.344,62.115,53.429l0.655,0.574\n      c2.828,2.476,6.354,3.713,9.88,3.713s7.052-1.238,9.88-3.713l0.655-0.574c21.813-19.085,42.302-36.544,62.118-53.431\n      c53.168-45.306,99.085-84.434,131.002-122.395C495.367,259.578,512,219.954,512,177.351\n      C512,138.213,498.733,101.605,474.644,74.27z M309.193,401.614c-17.08,14.554-34.658,29.533-53.193,45.646\n      c-18.534-16.111-36.113-31.091-53.196-45.648C98.745,312.939,30,254.358,30,177.351c0-31.83,10.605-61.394,29.862-83.245\n      C79.34,72.007,106.379,59.836,136,59.836c41.129,0,67.716,25.338,82.776,46.594c13.509,19.064,20.558,38.282,22.962,45.659\n      c2.011,6.175,7.768,10.354,14.262,10.354c6.494,0,12.251-4.179,14.262-10.354c2.404-7.377,9.453-26.595,22.962-45.66\n      c15.06-21.255,41.647-46.593,82.776-46.593c29.621,0,56.66,12.171,76.137,34.27C471.395,115.957,482,145.521,482,177.351\n      C482,254.358,413.255,312.939,309.193,401.614z' }))))))))), _createElement('div', { 'className': 'card-information' }, _createElement('div', { 'className': 'card-information__wrapper text-left' }, _createElement('div', { 'className': 'card-information__group card-information__group-2' }, _createElement('div', { 'className': 'card-vendor link-underline' }, _createElement('span', { 'className': 'visually-hidden' }, 'Vendor:'), _createElement('a', Object.assign({}, { 'href': this.vendor_url }, { dangerouslySetInnerHTML: { __html: this.vendor } })))), _createElement('a', {
                    'className': 'card-title link-underline card-title-ellipsis',
                    'href': this.url,
                    'data-product-title': this.removeHTML(this.title),
                    'data-product-url': this.url
                }, _createElement('span', mergeProps({ 'className': 'text' }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-price' }, _createElement('div', { 'className': 'price' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('dl', {}, _createElement('div', { 'className': 'price__regular' }, _createElement('dd', { 'className': 'price__last' }, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price)))), _createElement('div', { 'className': 'price__sale' }, _createElement('dd', { 'className': 'price__compare' }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price))), _createElement('dd', { 'className': 'price__last' }, _createElement('span', { 'className': 'price-item price-item--sale' }, this.formatPrice(this.price)))))))), _createElement('div', { 'className': 'card-action' }, this.ProductScope !== 'master' && this.variant_ids.length <= 1 && !this.out_of_stock ? _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'data-product-id': this.id,
                    'encType': 'multipart/form-data',
                    'key': '4654'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'quantity',
                    'value': '1'
                }), _createElement('button', {
                    'type': 'submit',
                    'className': 'product-form__submit button button--primary an-',
                    'id': 'product-add-to-cart'
                }, '\n              Add to cart\n            ')) : null, this.ProductScope === 'master' || this.variant_ids.length > 1 && !this.out_of_stock ? _createElement('a', {
                    'href': this.url,
                    'className': 'button button-ATC',
                    'data-product-handle': this.handle,
                    'key': '5216'
                }, '\n            Choose Options\n          ') : null, this.ProductScope !== 'master' && this.out_of_stock ? _createElement('button', {
                    'disabled': 'true',
                    'className': 'button button-ATC',
                    'data-product-handle': this.handle,
                    'key': '5465'
                }, '\n            Out of Stock\n          ') : null)))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []