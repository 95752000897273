import shopifyGenericDefaults from '../shopify-generic/config.js';

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['SearchBoxDialogButton'].includes(w.name)),
    {
      name: 'StickyGarage',
      type: 'Garage',
      location: '#cm-sticky-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { selector: '.mobile-search-input', class: 'cm_search-box-root__mobile' },
      template: 'searchBox/dialogButtonAdaptable',
    },
  ],
};
